















import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator'
import { format } from 'quasar'
import MembersSelector from '@/components/Meetings/components/MembersSelector/index.vue'
import { Contact } from '@tada-team/tdproto-ts'

@Component({
  components: {
    MembersSelector,
  },
})
export default class CalendarMainHeader extends Vue {
  @VModel({
    type: Object,
  }) contact!: Contact

  @Prop({
    type: String,
    required: true,
  }) date!: string

  get currentDate () {
    return format.capitalize(new Intl.DateTimeFormat(this.$i18n.locale, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(this.date)))
  }

  get placeholder () {
    return this.$t('calendar.membersSelector.placeholder').toString()
  }

  @Watch('contact')
  private watchContact () {
    window.goal('Calendar', {
      Calendar: this.contact
        ? 'Календарь — Выбрать другого пользователя'
        : 'Календарь — Выбрать свой календарь',
    })
  }
}
